import { cloneElement, createRef, useMemo } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import NavWindowDesktopItemLevel from "./NavWindowDesktopItemLevel.jsx";
import { useMutation } from "@tanstack/react-query";
import classNames from "classnames";
import * as constants from "../constants.js";
import * as h from "../helpers.js";

function NavDesktop({ user, selectedTopLevelItem }) {
  const canShowDeadLinks = h.getCanShowDeadLinks(user);

  function partitionItemsBasedOnHeight(items) {
    if (!items) {
      return [[], [], []];
    }
    const [
      totalHeight,
      itemsWithHeight,
      lastIsChildlessPaddingBottomDifference,
    ] = prepareItemsForHeightDivision(items);

    const targetColumnHeightWhenLastLeftAndMiddleItemsHaveChildren =
      totalHeight / 3;
    const targetColumnHeightWhenOneLastLeftOrMiddleItemIsChildless =
      (totalHeight + lastIsChildlessPaddingBottomDifference) / 3;
    const targetColumnHeightWhenTwoLastLeftOrMiddleItemsAreChildless =
      (totalHeight + 2 * lastIsChildlessPaddingBottomDifference) / 3;

    let columnDivisions = [];

    function divideColumns(items, firstColumn, secondColumn) {
      if (!items) return;
      const currentColumnIndex =
        secondColumn && firstColumn ? 2 : firstColumn ? 1 : 0;

      function getColumnBaseHeight(column) {
        if (!column || !column.length) {
          return 0;
        }
        return column.reduce((height, item) => {
          return height + item.height;
        }, 0);
      }
      function getLastItemInColumnWasChildless(column) {
        return column && column.length
          ? column[column.length - 1].children.length === 0
          : false;
      }
      let columnBaseHeights = [
        getColumnBaseHeight(firstColumn),
        getColumnBaseHeight(secondColumn),
        0,
      ];
      let columns = [firstColumn || [], secondColumn || [], []];
      const lastColumnItemsWereChildless = [
        getLastItemInColumnWasChildless(firstColumn),
        getLastItemInColumnWasChildless(secondColumn),
      ];
      let remainingItems = [];

      // if we are on the last column, add remaining items and height to last column and push those values to columnDivisions accumulator and end function without further recursion
      if (currentColumnIndex === 2) {
        columns[2] = items;
        columnBaseHeights[2] = getColumnBaseHeight(items);

        // adjust columnBaseHeights based on whether their last item is childless
        const adjustedColumnHeights = [...columnBaseHeights];
        for (let [
          index,
          wasChildless,
        ] of lastColumnItemsWereChildless.entries()) {
          if (wasChildless) {
            adjustedColumnHeights[index] =
              adjustedColumnHeights[index] +
              lastIsChildlessPaddingBottomDifference;
          }
        }
        if (canShowDeadLinks) {
          adjustedColumnHeights[2] = adjustedColumnHeights[2] + 4.03;
        }
        columnDivisions.push({
          columns: columns,
          columnHeights: adjustedColumnHeights,
        });
        return;
      } else {
        // push items to current column till they are taller than target height (which shifts based on whether the last item is childless), then push all remaining items to their own array
        for (let item of items) {
          const nColumnsWithChildlessLastItem =
            lastColumnItemsWereChildless.reduce(
              (count, wasChildless) => (wasChildless ? count + 1 : count),
              0
            );
          const currentTargetColumnHeight =
            nColumnsWithChildlessLastItem === 2
              ? targetColumnHeightWhenTwoLastLeftOrMiddleItemsAreChildless
              : nColumnsWithChildlessLastItem === 1
              ? targetColumnHeightWhenOneLastLeftOrMiddleItemIsChildless
              : targetColumnHeightWhenLastLeftAndMiddleItemsHaveChildren;
          const currentColumnHeight = lastColumnItemsWereChildless[
            currentColumnIndex
          ]
            ? columnBaseHeights[currentColumnIndex] +
              lastIsChildlessPaddingBottomDifference
            : columnBaseHeights[currentColumnIndex];

          if (currentColumnHeight < currentTargetColumnHeight) {
            columns[currentColumnIndex].push(item);
            columnBaseHeights[currentColumnIndex] =
              columnBaseHeights[currentColumnIndex] + item.height;
            lastColumnItemsWereChildless[currentColumnIndex] =
              !h.hasChildren(item);
          } else {
            remainingItems.push(item);
          }
        }
        // if currentColumnIndex is less than 2, call divideColumns again with appropriate arguments to continue division
        // prepare two options for further division: one as already calculated, and one with the last item of the current column shifted back to remainingItems to try out a shorter current column
        const shortenedCurrentColumn = columns[currentColumnIndex].slice(0, -1);
        const lastItemOfCurrentColumn =
          columns[currentColumnIndex].slice(-1)[0];
        const lengthenedRemainingItems = [
          lastItemOfCurrentColumn,
          ...remainingItems,
        ];
        if (currentColumnIndex === 0) {
          divideColumns(remainingItems, columns[0]);
          if (shortenedCurrentColumn.length > 0) {
            // we create an exception to keep the Affiliate Companies item in Services as the last item of the first column by skipping the division of columns where it is moved to the second column
            const lastColumnOneItem = columns[0].slice(-1)[0];
            const lastColumnOneItemIsAffiliateCompanies =
              lastColumnOneItem.title === "Affiliate Companies";
            if (!lastColumnOneItemIsAffiliateCompanies) {
              divideColumns(lengthenedRemainingItems, shortenedCurrentColumn);
            }
          }
        } else if (currentColumnIndex === 1) {
          divideColumns(remainingItems, columns[0], columns[1]);
          if (shortenedCurrentColumn.length > 0) {
            // we create an exception to keep the Business Support item in Services as the last item of the second column by skipping the division of columns where it is moved to the third column
            const lastColumnTwoItem = columns[1].slice(-1)[0];
            const lastColumnTwoItemIsBusinessSupport =
              lastColumnTwoItem.title === "Business Support";
            if (!lastColumnTwoItemIsBusinessSupport) {
              divideColumns(
                lengthenedRemainingItems,
                columns[0],
                shortenedCurrentColumn
              );
            }
          }
        }
      }
    }

    divideColumns(itemsWithHeight);

    // compare all columnDivisions based on height and choose the one that has the least tall tallest column
    let shortestTallestColumnHeight = Infinity;
    let bestFitColumnDivision = { columns: [], columnHeights: [] };
    for (let columnDivision of columnDivisions) {
      if (!columnDivision?.columnHeights) {
        return;
      }
      const tallestColumnHeight = Math.max(...columnDivision.columnHeights);
      if (tallestColumnHeight < shortestTallestColumnHeight) {
        shortestTallestColumnHeight = tallestColumnHeight;
        bestFitColumnDivision = columnDivision;
      }
    }
    return bestFitColumnDivision.columns;
  }

  function prepareItemsForHeightDivision(items) {
    const nColumnsOfChildren = 2;
    const withChldrenBaseHeight = 2.06;
    const withChildrenPaddingBottom = 2.7;
    const withChildrenHeightBeforeChildren =
      withChldrenBaseHeight + withChildrenPaddingBottom;

    const childlessBaseHeight = 1.333;
    const childlessPaddingBottom = 0.444;
    const childlessHeight = childlessBaseHeight + childlessPaddingBottom;
    const childlessHeightWhenLast =
      childlessBaseHeight + withChildrenPaddingBottom;

    const childBaseHeight = 1.22;
    const childrenHeightPerLine = childBaseHeight + childlessPaddingBottom;

    const lastIsChildlessPaddingBottomDifference =
      withChildrenPaddingBottom - childlessPaddingBottom;

    let totalHeight = 0;
    const nItems = items.length;
    const itemsWithHeight = items.reduce(
      (filteredAndWithHeight, item, index) => {
        if (!h.shouldShowItemToUser(item, user)) {
          return filteredAndWithHeight;
        }

        const nVisibleChildren = h.getVisibleChildren(item, user).length;
        // children are in columns so we count the number of lines of children, based on number of columns of children
        const childrenHeight =
          Math.ceil(nVisibleChildren / nColumnsOfChildren) *
          childrenHeightPerLine;
        const isLast = index + 1 === nItems;

        const itemHeight =
          nVisibleChildren > 0
            ? withChildrenHeightBeforeChildren + childrenHeight
            : isLast && !canShowDeadLinks
            ? childlessHeightWhenLast
            : childlessHeight;

        totalHeight = totalHeight + itemHeight;
        filteredAndWithHeight.push({ ...item, height: itemHeight });
        return filteredAndWithHeight;
      },
      []
    );

    // include height of Admin: Show Dead Links item, when present
    if (canShowDeadLinks) {
      totalHeight = totalHeight + childlessHeightWhenLast;
    }

    return [
      totalHeight,
      itemsWithHeight,
      lastIsChildlessPaddingBottomDifference,
    ];
  }

  const [
    selectedItemChildrenColLeft,
    selectedItemChildrenColMiddle,
    selectedItemChildrenColRight,
  ] = partitionItemsBasedOnHeight(selectedTopLevelItem?.children);

  const showDeadLinks = user.show_dead_links;
  const toggleShowDeadLinksMutation = useMutation([
    constants.toggleShowDeadLinksMutationName,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transitionNodeRef = useMemo(() => createRef(), [selectedTopLevelItem]);
  const transitionKey =
    selectedTopLevelItem.title + selectedTopLevelItem.order_no;

  return (
    <TransitionGroup
      appear={true}
      exit={true}
      component={null}
      childFactory={child =>
        cloneElement(child, {
          classNames: "mtt-nav-fade",
        })
      }
    >
      <CSSTransition
        key={transitionKey}
        timeout={{ enter: 200, exit: 150 }}
        classNames="mtt-nav-fade"
        nodeRef={transitionNodeRef}
      >
        <div
          className="mtt-hnb-desktop-nav"
          ref={transitionNodeRef}
          onClick={h.captureClick}
        >
          <div className="mtt-hnb-dn-col">
            {selectedItemChildrenColLeft.map(item => (
              <NavWindowDesktopItemLevel
                clickableHeaders={showDeadLinks}
                item={item}
                key={item.title + item.order_no}
                user={user}
              />
            ))}
          </div>
          <div className="mtt-hnb-dn-col">
            {selectedItemChildrenColMiddle.map(item => (
              <NavWindowDesktopItemLevel
                clickableHeaders={showDeadLinks}
                item={item}
                key={item.title + item.order_no}
                user={user}
              />
            ))}
          </div>
          <div className="mtt-hnb-dn-col">
            {selectedItemChildrenColRight.map(item => (
              <NavWindowDesktopItemLevel
                clickableHeaders={showDeadLinks}
                item={item}
                key={item.title + item.order_no}
                user={user}
              />
            ))}
            {canShowDeadLinks ? (
              <div
                className={classNames(
                  "mtt-hnb-dn-second-level-item mtt-hnb-second-level-item-admin-link"
                )}
              >
                <h3>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      if (!toggleShowDeadLinksMutation.isLoading) {
                        toggleShowDeadLinksMutation.mutate(
                          user.show_dead_links
                        );
                      }
                    }}
                    href={`${
                      user.show_dead_links ? "hide" : "show"
                    }-inactive-links`}
                  >
                    {`Admin: ${
                      user.show_dead_links ? "Hide" : "Show"
                    } inactive links`}
                  </a>
                </h3>
              </div>
            ) : null}
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default NavDesktop;
