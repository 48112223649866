import { useState } from "react";
import edgeLogo from "../icons/edge_logo_white.svg";
import menuIcon from "../icons/White Menu Icon.svg";
import MTDesktopLogo from "../icons/mohawk_today_lockup_white_logo.svg";
import MTMobileLogo from "../icons/MT_Logo_mobile.svg";
import SearchAsYouType from "./SearchAsYouType";
import * as constants from "../constants";
import * as h from "../helpers";
import classNames from "classnames";
import "./actionsAndStatusBar.css";

function ActionsAndStatusBar({ edgeStatus, isMobile, openMobileNav, user }) {
  const [userActionsOpen, setUserActionsOpen] = useState(false);
  const [userActionsOffClickAdded, setUserActionsOffClickAdded] =
    useState(false);

  const userInitials = h.getUserInitials(user);

  function addUserActionsOffClick() {
    const userActionsOffClick = () => {
      setUserActionsOpen(false);
      document.removeEventListener("click", userActionsOffClick);
      setUserActionsOffClickAdded(false);
    };

    // only add "off" click handler if it hasn't been added yet
    if (userActionsOffClickAdded === false) {
      document.addEventListener("click", userActionsOffClick);
      setUserActionsOffClickAdded(true);
    }
  }

  function handleUserMenuButtonClick(e) {
    if (!userActionsOpen) {
      addUserActionsOffClick();
      setUserActionsOpen(true);
      e.stopPropagation();
    }
  }

  function UserActions() {
    return (
      <div
        className={classNames("mtt-hasb-user-actions", {
          "mtt-hasb-user-actions-open": userActionsOpen,
        })}
      >
        <ul>
          {user.user_actions.map(action => (
            <li key={action.title}>
              <a href={action.url}>{action.title}</a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="mtt-header-actions-and-status-bar mt-text-normal">
      <a href={constants.MTLogoUrl} className="mt-icon-link">
        <img
          src={isMobile ? MTMobileLogo : MTDesktopLogo}
          alt="Mohawk Today"
          className="mtt-hasb-logo"
        />
      </a>

      {!isMobile ? (
        <div className="mtt-hasb-search-bar">
          <SearchAsYouType isMobile={isMobile} />
        </div>
      ) : null}

      <div className="mtt-hasb-right-side-container">
        {user?.fullname ? (
          <>
            <div
              className="mtt-hasb-initials-container"
              onClick={handleUserMenuButtonClick}
            >
              {userInitials}
              {isMobile ? <UserActions /> : null}
            </div>
            {!isMobile ? (
              <div
                className="mtt-hasb-user-actions-container"
                onClick={handleUserMenuButtonClick}
                role="button"
                title="Open Menu"
                aria-pressed={userActionsOpen}
              >
                <span className="mtt-hasb-greeting">Hi, {user.fullname}</span>
                <UserActions />
              </div>
            ) : null}
          </>
        ) : null}

        {user?.dealer?.account_code && !isMobile ? (
          <>
            <span className="mtt-no-shrink">
              Acct: {user.dealer.account_code}
            </span>
            <div className="mtt-hasb-divider"></div>
          </>
        ) : null}

        {edgeStatus && !isMobile ? (
          <a href={constants.edgeUrl}>
            <img
              className={classNames("mtt-hasb-edge-status-icon", {
                "mtt-hasb-edge-status-icon-mobile": isMobile,
              })}
              src={edgeStatus.whiteIcon}
              alt={`Edge ${edgeStatus.name} Icon`}
            />
          </a>
        ) : (
          <a
            className="button button-small button-edge-icon"
            href={constants.edgeUrl}
          >
            <img
              src={edgeLogo}
              alt="Edge"
              style={{ height: isMobile ? "1.26rem" : "1.416rem" }}
            />
          </a>
        )}
      </div>

      {isMobile ? (
        <div className="mtt-hasb-search-and-nav-container-mobile">
          <div className="mtt-hasb-search-bar">
            <SearchAsYouType isMobile={isMobile} />
          </div>
          <img
            src={menuIcon}
            alt="Menu Burger"
            role="button"
            className="clickable"
            onClick={openMobileNav}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ActionsAndStatusBar;
