import { useQuery } from "@tanstack/react-query";
import axiosMaker from "axios";
import * as constants from "../constants";

const axios = axiosMaker.create();
axios.defaults.withCredentials = true;

const getUserCacheBusted = () => {
  if (process.env.NODE_ENV === "development" && !constants.isPloneDev) {
    // return axios.get("data/user-admin-show.json");
    return axios.get("data/user-expanded-edge.json");
  }
  return axios.get(constants.getUserEndpoint, {
    params: { d: String(Date.now()) },
  });
};

function useUser() {
  const userQuery = useQuery(
    [constants.getUserEndpoint],
    function ({ queryKey }) {
      return axios
        .get(queryKey[0])
        .then(res => {
          // When the user logs out, sometimes the user is cached as an empty object
          // so when we receive an empty object, retry once with cache buster query
          if (
            Object.keys(res.data).length === 0 &&
            res.data.constructor === Object
          ) {
            return getUserCacheBusted();
          } else {
            return res;
          }
        })
        .then(
          res => {
            return res.data;
          },
          err => {
            console.log("In useUser, err:", err);
            return err;
          }
        );
    },
    {
      // We want to load a fresh user only on page load
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  return userQuery;
}

export default useUser;
